import { Currency } from './index';

const euro: Currency = {
  label: 'EUR',
  decimalDigits: 2,
  decimalSeparator: ',',
  thousandsSeparator: '.',
  currencySymbol: '€',
  currencySymbolNumberOfSpaces: 1,
  currencySymbolPosition: 'left',
};

export default {
  // current currency
  default: euro,

  // available currencies for user selection
  available: [euro],
};
