import { defineStore } from 'pinia';
import theme from '../configs/theme.ts';
import currencies from '../configs/currencies.ts';

const { globalTheme, menuTheme, toolbarTheme, isToolbarDetached, isContentBoxed, isRTL } = theme;

export const useAppStore = defineStore('app', {
  state: () => ({
    product: {
      name: 'Partner Portal',
    },

    // currency
    currency: currencies.default,
    availableCurrencies: currencies.available,

    // themes and layout configurations
    globalTheme,
    menuTheme,
    toolbarTheme,
    isToolbarDetached,
    isContentBoxed,
    isRTL,
  }),
  actions: {
    setGlobalTheme(value: 'light' | 'dark') {
      this.globalTheme = value;
    },
  },
});
