import { defineStore } from 'pinia';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export const useToastStore = defineStore('toast', () => {
  const { t } = useI18n();

  const color = ref<string>('black');
  const toastMessage = ref<string>('');
  const show = ref<boolean>(false);

  function showToast(message: string) {
    if (show.value) {
      show.value = false;
    }

    setTimeout(() => {
      color.value = 'black';
      toastMessage.value = message;
      show.value = true;
    });
  }

  function showError(error: Error, message?: string) {
    let errorMessage = error.message;
    if (axios.isAxiosError(error) && error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    setTimeout(() => {
      color.value = 'error';
      toastMessage.value = (message || t('error.unexpected.oops')) + ' ' + errorMessage;
      show.value = true;
    });
  }

  function showSuccess(message: string) {
    setTimeout(() => {
      color.value = 'success';
      toastMessage.value = message;
      show.value = true;
    });
  }

  return { showError, showSuccess, showToast, color, message: toastMessage, show };
});
